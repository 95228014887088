/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 언어
 */
export type StudentInstructorDtoLanguage =
  (typeof StudentInstructorDtoLanguage)[keyof typeof StudentInstructorDtoLanguage];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StudentInstructorDtoLanguage = {
  ko: "ko",
  en: "en",
} as const;
