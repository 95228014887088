import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import styled from "@emotion/styled";

import { FOOTER_MENUS, LINK_TYPE } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";
import { CHANNEL_TALK_URL, SNS_URL } from "@/constants/url";
import { LayoutConfig } from "@/types/layout-config";

const SNS_LIST = [
  {
    name: "youtube",
    link: SNS_URL.YOUTUBE,
    iconSrc: "/images/layout/youtube.svg",
  },
  {
    name: "instagram",
    link: SNS_URL.INSTAGRAM,
    iconSrc: "/images/layout/insta.svg",
  },
  {
    name: "naver blog",
    link: SNS_URL.BLOG,
    iconSrc: "/images/layout/blog.svg",
  },
  {
    name: "kakaotalk",
    link: SNS_URL.KAKAO,
    iconSrc: "/images/layout/kakao.svg",
  },
];

interface FooterProps {
  layoutConfig?: LayoutConfig;
  t: (key: string) => string;
}
export const Footer = ({ layoutConfig, t }: FooterProps) => {
  const router = useRouter();
  const lang = router.locale || "ko";
  const isKo = lang === "ko";

  const changeLanguage = (language: string) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query },
      },
      undefined,
      { locale: language },
    );
  };

  const convertLangLink = (link: string | { ko: string; en: string }) => {
    if (typeof link === "string") return link;
    return isKo ? link.ko : link.en;
  };

  return (
    <FooterContainer
      className={classNames({ disabled: layoutConfig?.isDisableFooter })}
    >
      <FooterInnerContainer>
        <FooterInnerRowContainer className="top">
          <FooterLogoWithCsContainer>
            <Logo isKo={lang === "ko"}>
              <img
                src={`/images/logo-white_${lang}.svg`}
                alt="케아클 로고"
              />
            </Logo>
            <Support>
              <SupportItem
                href={CHANNEL_TALK_URL}
                target={"_blank"}
              >
                {t("chat_consultation")}
              </SupportItem>
              <SupportItem
                href={CHANNEL_TALK_URL}
                target={"_blank"}
              >
                {t("support_board")}
              </SupportItem>
            </Support>
            <Comment>
              <CommentTitle>{t("customer_center")}</CommentTitle>
              <CommentContent>*{t("business_hours")}</CommentContent>
            </Comment>
          </FooterLogoWithCsContainer>
          <FooterMenusContainer>
            <ul className="main">
              {FOOTER_MENUS.filter(
                (menu) => isKo || menu.name !== "footer_kac",
              ).map((menu, index) => (
                <li key={`footer-menu-${index}`}>
                  <span className="main">{t(`${menu.name}`)}</span>
                  <ul className="sub">
                    {menu.child?.map((sub, subIndex) => (
                      <li
                        key={`footer-sub-menu-${subIndex}`}
                        className="sub"
                      >
                        <Link
                          passHref
                          href={convertLangLink(sub.link.url)}
                          target={
                            sub.link.type === LINK_TYPE.BLANK
                              ? "_blank"
                              : "_self"
                          }
                        >
                          <span className="sub">{t(`${sub.name}`)}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              <li>
                <span className="main">{t("language")}</span>

                <ul className="sub">
                  <li
                    className="sub"
                    onClick={() => changeLanguage("ko")}
                  >
                    <a>
                      <span className={`sub ${lang === "ko" && "active"}`}>
                        한국어
                      </span>
                    </a>
                  </li>
                  <li
                    className="sub"
                    onClick={() => changeLanguage("en")}
                  >
                    <a>
                      <span className={`sub ${lang === "en" && "active"}`}>
                        English
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </FooterMenusContainer>
        </FooterInnerRowContainer>
        <FooterInnerRowContainer className="middle">
          <SnsContainer>
            {SNS_LIST.map((sns, index) => (
              <SnsItem key={`sns-item-${index}`}>
                <Link
                  target={"_blank"}
                  href={sns.link}
                  passHref
                >
                  <img
                    src={sns.iconSrc}
                    alt={sns.name}
                  />
                </Link>
              </SnsItem>
            ))}
          </SnsContainer>
        </FooterInnerRowContainer>
        <FooterInnerRowContainer className="bottom">
          <p className="pc tablet">
            <span>{t("ceo")}</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
            <span>{t("company_address")}</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
            <span>{t("business_number")}</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
            <span>{t("business_report_number")}</span>
          </p>
          <p className="pc tablet">
            <span>TEL : 010-2367-2024</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
            <span>E-MAIL : kacmediagroup@k-artistclass.com</span>
          </p>

          <p className="tablet-sm mobile">
            <span>{t("ceo")}</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
          </p>
          <p className="tablet-sm mobile">
            <span>{t("company_address")}</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
          </p>
          <p className="tablet-sm mobile">
            <span>{t("business_number")}</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
          </p>
          <p className="tablet-sm mobile">
            <span>{t("business_report_number")}</span>
          </p>
          <p className="tablet-sm mobile">
            <span>TEL : 010-2367-2024</span>
            <img
              src={"/images/layout/|.svg"}
              alt={"divider-icon"}
            />
          </p>
          <p className="tablet-sm mobile">
            <span>E-MAIL : kacmediagroup@k-artistclass.com</span>
          </p>

          <p
            className="pc tablet tablet-sm mobile last-word"
            dangerouslySetInnerHTML={{ __html: t("not_responsible") }}
          />
        </FooterInnerRowContainer>
      </FooterInnerContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  width: 100%;

  padding: 40px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.brand.secondary.default};

  ${({ theme }) => theme.media.tabletSm} {
    padding: 40px 0 80px;
  }
`;

const FooterInnerContainer = styled.div`
  padding: 0 16px;

  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;

  ${({ theme }) => theme.media.tablet} {
  }
`;

const FooterInnerRowContainer = styled.div`
  width: 100%;

  &.top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &.middle {
  }

  &.bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 4px;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
      letter-spacing: -0.12px;
      color: ${({ theme }) => theme.colors.text.disabled.white};

      &.last-word {
        ${({ theme }) => theme.typography.body3.m};
        color: ${({ theme }) => theme.colors.text.disabled.white};
      }
    }
  }

  ${({ theme }) => theme.media.tablet} {
    &.top {
      align-items: flex-start;
      flex-direction: column;
      gap: 40px;
    }
  }

  ${({ theme }) => theme.media.tabletSm} {
  }
`;

const FooterLogoWithCsContainer = styled.div``;

const Logo = styled.div<{ isKo?: boolean | true }>`
  max-width: ${({ isKo }) => (isKo ? "125px" : "158px")};
  img {
    width: 100%;
    flex-shrink: 0;
  }
`;

const Support = styled.div`
  margin-top: 24px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const SupportItem = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 24px;
  letter-spacing: -0.4px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.icon.inactive.white};
  background: ${({ theme }) => theme.colors.brand.secondary.default};

  ${({ theme }) => theme.typography.body1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const Comment = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CommentTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.high.white};
`;

const CommentContent = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 12px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const FooterMenusContainer = styled.div`
  ul {
    display: flex;

    &.main {
      gap: 32px;
    }

    &.sub {
      margin-top: 16px;
      flex-direction: column;
      gap: 16px;
    }

    li {
      span {
        &.main {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 14px */
          letter-spacing: -0.4px;
          color: ${({ theme }) => theme.colors.text.high.white};
        }
      }

      a {
        span {
          &.sub {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 14px */
            letter-spacing: -0.4px;
            color: ${({ theme }) => theme.colors.text.medium.white};
          }
          &.active {
            color: ${({ theme }) => theme.colors.text.high.white};
          }
        }
      }
    }
  }

  ${({ theme }) => theme.media.tabletSm} {
    ul {
      &.main {
        display: grid;
        grid-template-columns: 100px 100px;
        gap: 32px;
      }
    }
  }
`;

const SnsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SnsItem = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`;
