/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * Course Cycle의 현재 상태
 */
export type CourseProcessStatusEnum =
  (typeof CourseProcessStatusEnum)[keyof typeof CourseProcessStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseProcessStatusEnum = {
  PLANNED: "PLANNED",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
} as const;
