import { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { LINK_TYPE, MENU } from "@/constants/menu";
import { Z_INDEX_VALUE } from "@/constants/style/layout";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useProfile } from "@/hooks/auth/use-profile";
import { HamburgerMenuItem } from "@/layout/header/hamburger-menu/hamburger-menu-item";
import {
  checkedTeacherWithAcademyTeacher,
  logout,
  RoleEnum,
} from "@/utils/auth";

import { useCourseControllerGetSectionsGlobal } from "@shared/generated/api/fn/kac/course/course";

interface HamburgerMenuProps {
  isHamburgerMenuOpen: boolean;
  closeHamburgerMenu: () => void;
  t: (key: string) => string;
}

export const HamburgerMenu = ({
  isHamburgerMenuOpen,
  closeHamburgerMenu,
  t,
}: HamburgerMenuProps) => {
  const { accessToken } = useAccessToken();
  const { data: profile } = useProfile();
  const isTeacherWithAcademyTeacher = useMemo(
    () =>
      checkedTeacherWithAcademyTeacher((profile?.roles as RoleEnum[]) || []),
    [profile?.roles],
  );

  const [subMenuActiveIndex, setSubMenuActiveIndex] = useState<null | number>(
    null,
  );

  const router = useRouter();
  const lang = (router.locale as "ko" | "en") || "ko";
  const isKo = lang === "ko";
  const { data: sectionData } = useCourseControllerGetSectionsGlobal(lang, {
    visible: true,
  });

  useEffect(() => {
    if (!isHamburgerMenuOpen) {
      setSubMenuActiveIndex(null);
    }
  }, [isHamburgerMenuOpen]);

  const openSubMenu = (index: number) => {
    if (index === subMenuActiveIndex) {
      setSubMenuActiveIndex(null);
      return;
    }
    setSubMenuActiveIndex(index);
  };

  const onLogout = async () => {
    closeHamburgerMenu();
    await logout({
      redirect: true,
    });
  };

  return (
    <HamburgerMenuContainer
      className={classNames({
        active: isHamburgerMenuOpen,
      })}
    >
      <TopContainer>
        <AvatarContainer>
          {!accessToken && (
            <Link
              className="user"
              href={MENU.LOGIN.link.url}
              onClick={closeHamburgerMenu}
              passHref
            >
              <img
                src={"/images/avatar.svg"}
                alt={"avatar"}
              />
              <span>
                {t(`${MENU.LOGIN.name}`)}
                <LoginArrowIcon icon={ICON_SVG.LINE.SIZE_16.ICON_ARROW_RIGHT} />
              </span>
            </Link>
          )}

          {accessToken && (
            <a className="user">
              <img
                src={"/images/avatar.svg"}
                alt={"avatar"}
              />
              <span>{profile?.name}</span>
            </a>
          )}
        </AvatarContainer>
        <a onClick={closeHamburgerMenu}>
          <CloseIcon icon={ICON_SVG.LINE.SIZE_24.ICON_CLOSE} />
        </a>
      </TopContainer>

      {accessToken && (
        <AuthMenuContainer>
          <ul>
            <li>
              <Link
                href={MENU.MY_CLASS.link.url}
                onClick={closeHamburgerMenu}
                passHref
              >
                <Icon icon={ICON_SVG.LINE.SIZE_12.ICON_PLAY} />
                <span>{t("my_class")}</span>
              </Link>
            </li>
            <li>
              <Link
                href={MENU.MY_ORDERS.link.url}
                onClick={closeHamburgerMenu}
                passHref
              >
                <Icon icon={ICON_SVG.LINE.SIZE_12.ICON_BILL} />
                <span>{t("order_list")}</span>
              </Link>
            </li>
            <li>
              <Link
                href={MENU.MY_PROFILE.link.url}
                onClick={closeHamburgerMenu}
                passHref
              >
                <Icon icon={ICON_SVG.LINE.SIZE_12.ICONS_SETTING} />
                <span>{t("edit_my_info")}</span>
              </Link>
            </li>
          </ul>
        </AuthMenuContainer>
      )}

      <MenuContainer>
        <ul className="main">
          <li className="top">
            <span>{t("category")}</span>

            {isKo &&
              (isTeacherWithAcademyTeacher ? (
                <Link
                  href={MENU.TEACHERS_ROOM_SERVICE.link.url}
                  target={"_blank"}
                  onClick={closeHamburgerMenu}
                  passHref
                >
                  <span>{t(`${MENU.TEACHERS_ROOM_SERVICE.name}`)}</span>
                  <LinkIcon icon={ICON_SVG.LINE.SIZE_16.ICON_LINK} />
                </Link>
              ) : (
                <Link
                  href={MENU.TEACHER_SUPPORT.link.url}
                  target={
                    MENU.TEACHER_SUPPORT.link.type === LINK_TYPE.SELF
                      ? "_self"
                      : "_blank"
                  }
                  onClick={closeHamburgerMenu}
                  passHref
                >
                  {t(`${MENU.TEACHER_SUPPORT.name}`)}
                </Link>
              ))}
          </li>

          {sectionData?.map((menu, index) => (
            <HamburgerMenuItem
              key={`menu-row-${index}`}
              main={menu}
              index={index}
              closeHamburgerMenu={closeHamburgerMenu}
            />
          ))}

          {accessToken && (
            <>
              <li className={"divider"} />
              <li className="main">
                <a onClick={onLogout}>
                  <span className="logout">{t("signout")}</span>
                </a>
              </li>
            </>
          )}
        </ul>
      </MenuContainer>
    </HamburgerMenuContainer>
  );
};

const HamburgerMenuContainer = styled.div`
  display: none;

  z-index: ${Z_INDEX_VALUE.HAMBURGER_MENU_CONTAINER};
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  background-color: ${({ theme }) =>
    theme.colors.environment.background.default};

  flex-direction: column;

  &.active {
    display: flex;
  }
`;

const TopContainer = styled.div`
  padding: 18px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.environment.background.on};
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    &.user {
      cursor: pointer;
      display: flex;
      align-items: center;

      gap: 8px;

      span {
        display: flex;
        align-items: center;
        gap: 2px;

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.4px;

        color: ${({ theme }) => theme.colors.text.high.white};
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const LoginArrowIcon = styled(Icon)`
  width: 16px;
  height: 16px;

  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`;

const CloseIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`;

const AuthMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.environment.background.on};

  width: 100%;
  ul {
    width: 100%;
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      a {
        display: flex;
        align-items: center;
        gap: 4px;

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 16px */
        letter-spacing: -0.4px;

        color: ${({ theme }) => theme.colors.icon.active.white};
      }
    }
  }
`;

const MenuContainer = styled.div`
  ul {
    &.sub {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.2s ease-out 0s;

      &.active {
        flex-direction: column;
        max-height: 100vh;
        transition: max-height 0.4s ease-in 0s;
      }
    }

    li {
      &.top {
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray95};

        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 500;
        font-size: 16px;
        line-height: 100%; /* 16px */
        letter-spacing: -0.4px;
        color: ${({ theme }) => theme.colors.text.high.black};

        a {
          font-weight: 700;
          display: flex;
          align-content: center;
          gap: 2px;
          padding: 0;
          color: ${({ theme }) => theme.colors.text.high.black};
        }
      }

      &.sub {
        background-color: ${({ theme }) => theme.colors.gray.gray95};
      }

      &.divider {
        height: 8px;
        align-self: stretch;
        background-color: ${({ theme }) => theme.colors.gray.gray95};
      }

      a {
        padding: 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          &.main {
            width: 100%;
            align-items: center;
            ${({ theme }) => theme.typography.body1.b};
          }

          &.sub {
            ${({ theme }) => theme.typography.body1.m};
          }

          &.logout {
            ${({ theme }) => theme.typography.body1.m};
            color: ${({ theme }) => theme.colors.text.medium.black};
          }
        }
      }
    }
  }
`;

const LinkIcon = styled(Icon)`
  width: 18px;
  height: 18px;

  path {
    stroke: ${({ theme }) => theme.colors.icon.active.black};
  }
`;
