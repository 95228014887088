/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 공지 대상 타입 (여러 대상 선택 가능)
 */
export type NoticeTargetTypeEnum =
  (typeof NoticeTargetTypeEnum)[keyof typeof NoticeTargetTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoticeTargetTypeEnum = {
  STUDENT: "STUDENT",
  INSTRUCTOR: "INSTRUCTOR",
  ACADEMY: "ACADEMY",
} as const;
