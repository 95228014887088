/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 문의 상태
 */
export type InquiryResponseDtoStatus =
  (typeof InquiryResponseDtoStatus)[keyof typeof InquiryResponseDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InquiryResponseDtoStatus = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  ANSWERED: "ANSWERED",
  REOPENED: "REOPENED",
  CLOSED: "CLOSED",
} as const;
