/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 강의 언어 선택(ko, en) - 미기입시 ko로 설정됌
 */
export type LanguageEnum = (typeof LanguageEnum)[keyof typeof LanguageEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LanguageEnum = {
  ko: "ko",
  en: "en",
} as const;
