/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 강의 난이도
 */
export type CourseInfoDtoDifficulty =
  (typeof CourseInfoDtoDifficulty)[keyof typeof CourseInfoDtoDifficulty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseInfoDtoDifficulty = {
  BEGINNER: "BEGINNER",
  INTERMEDIATE: "INTERMEDIATE",
  ADVANCED: "ADVANCED",
  EXPERT: "EXPERT",
} as const;
