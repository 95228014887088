/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 포인트 변경 유형
 */
export type UserPointLogResponseDtoPointChangeType =
  (typeof UserPointLogResponseDtoPointChangeType)[keyof typeof UserPointLogResponseDtoPointChangeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPointLogResponseDtoPointChangeType = {
  REWARD: "REWARD",
  PURCHASE: "PURCHASE",
  ADMIN_GRANT: "ADMIN_GRANT",
  SIGNUP_BONUS: "SIGNUP_BONUS",
  EXPIRED: "EXPIRED",
} as const;
