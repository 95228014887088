/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 인증 방법 (전화번호, 이메일)
 */
export type VerificationQueryDtoMethod =
  (typeof VerificationQueryDtoMethod)[keyof typeof VerificationQueryDtoMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationQueryDtoMethod = {
  EMAIL: "EMAIL",
  SMS: "SMS",
} as const;
