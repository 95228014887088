import { useSession } from "@/hooks/auth/use-session";

export const useAccessToken = () => {
  const { data: session, status } = useSession();

  return {
    accessToken: session?.accessToken ? session.accessToken : undefined,
    isLoading: status === "loading",
    isAuthenticated: status === "authenticated" && !!session?.accessToken,
  };
};
