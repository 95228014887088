/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 승인 상태 (REQUESTED, APPROVED, REJECTED)
 */
export type AcademyAdminAccountInfoDtoStatus =
  (typeof AcademyAdminAccountInfoDtoStatus)[keyof typeof AcademyAdminAccountInfoDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyAdminAccountInfoDtoStatus = {
  REQUESTED: "REQUESTED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCEL_REQ: "CANCEL_REQ",
  CANCELLED: "CANCELLED",
  INVITED: "INVITED",
} as const;
