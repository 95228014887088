import { ReactNode } from "react";
import { useRouter } from "next/router";
import enTranslations from "public/locales/en/common.json";
import koTranslations from "public/locales/ko/common.json";
import styled from "@emotion/styled";

import { HEADER_HEIGHT } from "@/constants/style/layout";
import { Footer } from "@/layout/footer/footer";
import { Header } from "@/layout/header/header";
import { LayoutConfig } from "@/types/layout-config";

const translationsMap: Record<string, Record<string, string>> = {
  en: enTranslations,
  ko: koTranslations,
};

interface RootLayoutProps {
  children: ReactNode;
  layoutConfig?: LayoutConfig;
}

export const RootLayout = ({ children, layoutConfig }: RootLayoutProps) => {
  const router = useRouter();
  const locale = router.locale || "ko";
  const translations = translationsMap[locale] || {};

  return (
    <>
      <Header
        layoutConfig={layoutConfig}
        t={(key: string) => translations[key] || key}
      />
      <Main>{children}</Main>
      <Footer
        layoutConfig={layoutConfig}
        t={(key: string) => translations[key] || key}
      />
    </>
  );
};

const Main = styled.main`
  padding-top: ${HEADER_HEIGHT.PC}px;

  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.tablet} {
    padding-top: ${HEADER_HEIGHT.TABLET}px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding-top: ${HEADER_HEIGHT.MOBILE}px;
  }
`;
