import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import qs from "qs";

import { openToast } from "@shared/lib/utils/toast";
import { signOut } from "next-auth/react";

const paramsSerializer = (params: any) => qs.stringify(params);

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_HOST,
  paramsSerializer,
});

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    if (response.data && response.data.body) {
      response.data = response.data.body;
    }
    return response;
  },
  async (error) => {
    const status = error?.response?.status;

    if (status === 401) {
      openToast("ERROR", "세션이 만료되었습니다. 다시 로그인해주세요.");

      await signOut({ callbackUrl: "/login" });
      return Promise.reject(error);
    }

    if (error?.code === "ERR_NETWORK") {
      openToast(
        "ERROR",
        "일시적인 장애가 생겼습니다. 잠시 후 다시 시도해주세요.",
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      error.response.data = error.response.data.error;
    }

    return Promise.reject(error);
  },
);

interface Options {
  accessToken?: string;
  headers?: Record<string, any>;
}

/**
 * options 라는 파라미터를 정의했지만
 * 자동생성 될 때 request라고 orval에서 변경됨
 *
 * @param config
 * @param options
 */
export const axiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: Options,
): Promise<T> => {
  config.headers = {
    ...config.headers,
    ...options?.headers,
  };

  if (options?.accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${options?.accessToken}`,
    };
  }

  const promise = AXIOS_INSTANCE({ ...config }).then(({ data }) => data);
  return promise;
};

export default AXIOS_INSTANCE;
export type ErrorType<Error> = AxiosError<Error>;
