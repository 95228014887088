/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 미디어 타입
 */
export type AcademyMediaResponseDtoType =
  (typeof AcademyMediaResponseDtoType)[keyof typeof AcademyMediaResponseDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyMediaResponseDtoType = {
  INTRO_VIDEO: "INTRO_VIDEO",
  INTRO_IMAGE: "INTRO_IMAGE",
  PC_MAIN_BANNER: "PC_MAIN_BANNER",
  MOBILE_MAIN_BANNER: "MOBILE_MAIN_BANNER",
} as const;
