/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 강의 난이도 (BEGINNER: 입문, INTERMEDIATE: 중급, ADVANCED: 고급, EXPERT: 전문가)
 */
export type DifficultyEnum =
  (typeof DifficultyEnum)[keyof typeof DifficultyEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DifficultyEnum = {
  BEGINNER: "BEGINNER",
  INTERMEDIATE: "INTERMEDIATE",
  ADVANCED: "ADVANCED",
  EXPERT: "EXPERT",
} as const;
