export const ACADEMY_URL = `${process.env.NEXT_PUBLIC_ACADEMY_HOST}`;

export const SNS_URL = {
  INSTAGRAM: "https://www.instagram.com/k_artist_class/",
  FACEBOOK: "https://www.facebook.com/",
  BLOG: "https://blog.naver.com/k-artistclass",
  YOUTUBE: "https://www.youtube.com/@kartistclass.8696",
  KAKAO: "http://pf.kakao.com/_ZhxgJs",
};

// 공지사항
export const NOTIFICATION_RUL = {
  en: "https://www.notion.so/kacmediagroup/Student-GUIDE-17ddc5a604d38100ac5fd6f24ab0c8dc?pvs=4#17ddc5a604d381239af1eb7a8c5d930c",
  ko: "https://www.notion.so/kacmediagroup/14adc5a604d380a08d48d51e809f3d9d?pvs=4#157dc5a604d3806a931deb37486c8b20",
};

// 서비스 이용가이드
export const SERVICE_GUIDE_URL = {
  en: "https://kacmediagroup.notion.site/Student-GUIDE-17ddc5a604d38100ac5fd6f24ab0c8dc",
  ko: "https://kacmediagroup.notion.site/14adc5a604d380a08d48d51e809f3d9d",
};

// 줌사용방법
export const ZOOM_GUIDE_URL = {
  en: "https://kacmediagroup.notion.site/ZOOM-Class-17ddc5a604d3811cb2fdce2f76478b2e",
  ko: "https://kacmediagroup.notion.site/ZOOM-64ddf8451053467b843262fbf563f50d",
};

// 개인정보수집 약관
export const PRIVACY_POLICY_URL = {
  en: "https://kacmediagroup.notion.site/Privacy-Policy-17ddc5a604d3815d97fceaea4c2d107a",
  ko: "https://kacmediagroup.notion.site/d7c81bf0dcdb4dfe970fbb5c69648d03",
};

// 서비스 이용약관
export const SERVICE_POLICY_URL = {
  en: "https://kacmediagroup.notion.site/Terms-of-Servic-17ddc5a604d381989221c58a2da173b3",
  ko: "https://kacmediagroup.notion.site/d8a47ee6ef47454897c69bb395a652ce",
};

// 자주 묻는 질문
export const FAQ_URL = {
  en: "https://www.notion.so/kacmediagroup/Student-GUIDE-17ddc5a604d38100ac5fd6f24ab0c8dc?pvs=4#17ddc5a604d381ad82b1dbc191f3bc79",
  ko: "https://www.notion.so/kacmediagroup/14adc5a604d380a08d48d51e809f3d9d?pvs=4#14adc5a604d380d980acf957972aaa7c",
};

// 환불 규정
export const REFUND_POLICY_URL = {
  en: "https://kacmediagroup.notion.site/Refund-Policy-17ddc5a604d38116a8e5dfad3d550bcb",
  ko: "https://kacmediagroup.notion.site/6a69e20968074693a3e9f585a62e74f3",
};

// 채널톡 상담
export const CHANNEL_TALK_URL = "http://79pko.channel.io/";
