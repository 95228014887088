/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

export type ApprovalStatusUpdateEnum =
  (typeof ApprovalStatusUpdateEnum)[keyof typeof ApprovalStatusUpdateEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalStatusUpdateEnum = {
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
} as const;
