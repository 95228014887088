import {
  ACADEMY_URL,
  FAQ_URL,
  NOTIFICATION_RUL,
  PRIVACY_POLICY_URL,
  REFUND_POLICY_URL,
  SERVICE_GUIDE_URL,
  SERVICE_POLICY_URL,
  ZOOM_GUIDE_URL,
} from "@/constants/url";

export const LINK_TYPE = {
  SELF: "SELF",
  BLANK: "BLANK",
  LABEL: "LABEL",
} as const;
export type LinkType = keyof typeof LINK_TYPE;

export const MENU = {
  CONNECT_USER: {
    name: "가입유저",
    link: {
      url: "/connect-user",
      type: LINK_TYPE.SELF,
    },
  },

  LOGIN: {
    name: "signin",
    link: {
      url: "/login",
      type: LINK_TYPE.SELF,
    },
  },

  SIGNUP: {
    name: "signup",
    link: {
      url: "/signup",
      type: LINK_TYPE.SELF,
    },
  },
  SIGNUP_SUCCESS: {
    name: "회원가입 완료",
    link: {
      url: "/signup/success",
      type: LINK_TYPE.SELF,
    },
  },
  FIND_PASSWORD: {
    name: "비밀번호 찾기",
    link: {
      url: "/find/password",
      type: LINK_TYPE.SELF,
    },
  },

  ACADEMY: {
    name: "ACADEMY",
    link: {
      url: "/academy",
      type: LINK_TYPE.SELF,
    },
  },
  ACADEMY1: {
    name: "아카데미",
    link: {
      url: "/academy",
      type: LINK_TYPE.SELF,
    },
  },
  ACADEMY_MUSIC: {
    name: "음악",
    link: {
      url: "/academy/MUSIC",
      type: LINK_TYPE.SELF,
    },
  },
  ACADEMY_VOICE_ACTING: {
    name: "성우",
    link: {
      url: "/academy/VOICE_ACTING",
      type: LINK_TYPE.SELF,
    },
  },

  LIVE_CLASS: {
    name: "LIVE",
    link: {
      url: "/class/live",
      type: LINK_TYPE.SELF,
    },
  },

  //TODO: 관리자에서 클래스 카테고리로 관리
  LIVE_CLASS_MUSIC: {
    name: "음악 입문",
    link: {
      url: "/class/live/music",
      type: LINK_TYPE.SELF,
    },
  },
  LIVE_CLASS_ALBUM: {
    name: "음원 발매 ",
    link: {
      url: "/class/live/album",
      type: LINK_TYPE.SELF,
    },
  },
  LIVE_CLASS_AUDIOBOOK: {
    name: "오디오북 출간",
    link: {
      url: "/class/live/audiobook",
      type: LINK_TYPE.SELF,
    },
  },
  LIVE_CLASS_VOICEACTING: {
    name: "성우",
    link: {
      url: "/class/live/voiceacting",
      type: LINK_TYPE.SELF,
    },
  },

  VOD_CLASS: {
    name: "VOD",
    link: {
      url: "/class/vod",
      type: LINK_TYPE.SELF,
    },
  },
  //TODO: 관리자에서 클래스 카테고리로 관리
  VOD_CLASS_DANCE: {
    name: "댄스",
    link: {
      url: "/class/vod/DANCE",
      type: LINK_TYPE.SELF,
    },
  },
  VOD_CLASS_VOICE_ACTING: {
    name: "성우",
    link: {
      url: "/class/vod/VOICE_ACTING",
      type: LINK_TYPE.SELF,
    },
  },
  VOD_CLASS_MUSIC: {
    name: "뮤직",
    link: {
      url: "/class/vod/MUSIC",
      type: LINK_TYPE.SELF,
    },
  },
  VOD_CLASS_CREATIVE: {
    name: "크리에이티브",
    link: {
      url: "/class/vod/CREATIVE",
      type: LINK_TYPE.SELF,
    },
  },

  MANAGEMENT: {
    name: "MANAGEMENT",
    link: {
      url: "/management",
      type: LINK_TYPE.SELF,
    },
  },

  EVENT: {
    name: "EVENT",
    link: {
      url: "/event",
      type: LINK_TYPE.SELF,
    },
  },

  TEACHER_SUPPORT: {
    name: "apply_teacher",
    link: {
      url: `/teacher/support`,
      type: LINK_TYPE.SELF,
    },
  },
  TEACHER_APPLY: {
    name: "강사 지원 등록",
    link: {
      url: `/teacher/apply`,
      type: LINK_TYPE.SELF,
    },
  },

  ACADEMY_SUPPORT: {
    name: "enter_academy",
    link: {
      url: `/academy`,
      type: LINK_TYPE.BLANK,
    },
  },
  TEACHER_SERVICE: {
    name: "teachers_room",
    link: {
      url: ACADEMY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  TEACHERS_ROOM_SERVICE: {
    name: "teachers_room",
    link: {
      url: ACADEMY_URL,
      type: LINK_TYPE.BLANK,
    },
  },

  MY_PAGE: {
    name: "edit_my_info",
    link: {
      url: "/my",
      type: LINK_TYPE.SELF,
    },
  },
  MY_CLASS: {
    name: "my_class",
    link: {
      url: "/my/class",
      type: LINK_TYPE.SELF,
    },
  },
  MY_CLASS_SCHEDULE: {
    name: "schedule",
    link: {
      url: "/my/schedule",
      type: LINK_TYPE.SELF,
    },
  },
  MY_ORDERS: {
    name: "order_list",
    link: {
      url: "/my/orders",
      type: LINK_TYPE.SELF,
    },
  },
  MY_COUPON: {
    name: "쿠폰",
    link: {
      url: "/my/coupon",
      type: LINK_TYPE.SELF,
    },
  },
  MY_POINT: {
    name: "point",
    link: {
      url: "/my/point",
      type: LINK_TYPE.SELF,
    },
  },
  MY_CLASS_QNA: {
    name: "inquiry_board",
    link: {
      url: "/my/inquiry",
      type: LINK_TYPE.SELF,
    },
  },
  CUSTOMER_CENTER: {
    name: "customer_center",
    link: {
      url: "/my/customer_center",
      type: LINK_TYPE.SELF,
    },
  },
  MY_PROFILE: {
    name: "edit_my_info",
    link: {
      url: "/my/profile",
      type: LINK_TYPE.SELF,
    },
  },
  LOGOUT: {
    name: "signout",
    link: {
      url: "/",
      type: LINK_TYPE.SELF,
    },
  },

  SERVICE_GUIDE: {
    name: "user_guide",
    link: {
      url: SERVICE_GUIDE_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  ZOOM_GUIDE: {
    name: "zoom_guide",
    link: {
      url: ZOOM_GUIDE_URL,
      type: LINK_TYPE.BLANK,
    },
  },

  FAQ: {
    name: "FAQ",
    link: {
      url: FAQ_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  SERVICE_POLICY: {
    name: "terms_of_use",
    link: {
      url: SERVICE_POLICY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  PRIVACY_POLICY: {
    name: "privacy_policy",
    link: {
      url: PRIVACY_POLICY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  REFUND_POLICY: {
    name: "refund_policy",
    link: {
      url: REFUND_POLICY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  NOTIFICATION: {
    name: "notice",
    link: {
      url: NOTIFICATION_RUL,
      type: LINK_TYPE.BLANK,
    },
  },
} as const;

export interface Menu {
  name: string;
  link: {
    url: string | { ko: string; en: string };
    type: LinkType;
  };

  child?: Menu[];
}

export const MENUS: Menu[] = [
  {
    ...MENU.LIVE_CLASS_MUSIC,
  },
  {
    ...MENU.LIVE_CLASS_ALBUM,
  },
  {
    ...MENU.LIVE_CLASS_AUDIOBOOK,
  },
  {
    ...MENU.LIVE_CLASS_VOICEACTING,
  },
  // {
  //   ...MENU.VOD_CLASS,
  //   child: [
  //     MENU.VOD_CLASS_DANCE,
  //     MENU.VOD_CLASS_VOICE_ACTING,
  //     MENU.VOD_CLASS_MUSIC,
  //     MENU.VOD_CLASS_CREATIVE,
  //   ],
  // },
] as const;

export const FOOTER_MENUS: Menu[] = [
  {
    name: "footer_kac",
    link: {
      url: "/",
      type: LINK_TYPE.LABEL,
    },
    child: [
      MENU.TEACHER_SUPPORT,
      MENU.ACADEMY_SUPPORT,
      MENU.TEACHERS_ROOM_SERVICE,
    ],
  },
  {
    name: "guide",
    link: {
      url: "/",
      type: LINK_TYPE.LABEL,
    },
    child: [MENU.SERVICE_GUIDE, MENU.ZOOM_GUIDE],
  },
  {
    name: "support",
    link: {
      url: "/",
      type: LINK_TYPE.LABEL,
    },
    child: [
      MENU.NOTIFICATION,
      MENU.FAQ,
      MENU.SERVICE_POLICY,
      MENU.PRIVACY_POLICY,
      MENU.REFUND_POLICY,
    ],
  },
] as const;

export const MY_MENUS: Menu = {
  ...MENU.MY_PAGE,
  child: [MENU.MY_CLASS, MENU.MY_PROFILE, MENU.MY_ORDERS],
} as const;
