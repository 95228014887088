/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 인증 유형 (회원가입, 프로필 업데이트, 비밀번호 재설정 등)
 */
export type VerificationQueryDtoType =
  (typeof VerificationQueryDtoType)[keyof typeof VerificationQueryDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationQueryDtoType = {
  SIGNUP: "SIGNUP",
  RESET_PASSWORD: "RESET_PASSWORD",
} as const;
