/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 유저 상태
 */
export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PENDING: "PENDING",
} as const;
