/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 문의 유형
 */
export type InquiryResponseDtoType =
  (typeof InquiryResponseDtoType)[keyof typeof InquiryResponseDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InquiryResponseDtoType = {
  COURSE_GENERAL: "COURSE_GENERAL",
  COURSE_SCHEDULE: "COURSE_SCHEDULE",
  COURSE_QNA: "COURSE_QNA",
  SERVICE_GENERAL: "SERVICE_GENERAL",
} as const;
