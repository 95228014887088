/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 강의 유형
 */
export type CourseInfoDtoType =
  (typeof CourseInfoDtoType)[keyof typeof CourseInfoDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseInfoDtoType = {
  SINGLE: "SINGLE",
  MULTI: "MULTI",
  SUBSCRIPTION: "SUBSCRIPTION",
} as const;
