/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

export type FileControllerGetSignedUrlScope =
  (typeof FileControllerGetSignedUrlScope)[keyof typeof FileControllerGetSignedUrlScope];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileControllerGetSignedUrlScope = {
  public: "public",
  private: "private",
} as const;
