/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 스케줄 상태
 */
export type ScheduleResponseDtoStatus =
  (typeof ScheduleResponseDtoStatus)[keyof typeof ScheduleResponseDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduleResponseDtoStatus = {
  PENDING: "PENDING",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  READY_TO_COMPLETE: "READY_TO_COMPLETE",
  MEETING_READY: "MEETING_READY",
} as const;
