/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */

/**
 * 결제 상태 -  PENDING: '결제 진행 중',SUCCESS: '결제 성공(가상계좌 발급 성공)', FAILURE: '결제 실패', CANCELED: '결제 취소', REFUNDED: '환불 '
 */
export type PaymentGatewayLogsForPurchaseStatus =
  (typeof PaymentGatewayLogsForPurchaseStatus)[keyof typeof PaymentGatewayLogsForPurchaseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentGatewayLogsForPurchaseStatus = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  CANCELED: "CANCELED",
  REFUNDED: "REFUNDED",
} as const;
